<!-- component -->
<template>
  <div class="p-0 bg-white -ml-2" >
    <div class="bg-white">
      <div class="pt-2 px-1 grid grid-cols-12 gap-x-2 ">
        <div class="box col-span-7 bg-white h-full cust_card border border-gray-200 rounded-md p-5 relative">
          <div class="">
            <p class="heading-3 text-text2">Project:</p>
            <p class="heading-4 text-text1 pl-2">{{filterObject.projectName}}</p>
          </div>
          <div class="mt-5">
            <p class="heading-3 text-text2">Description:</p>
            <p class="heading-4 text-text1 pl-2">{{filterObject.notes}}</p>
          </div>
          <div class="mt-5">
            <p class="heading-3 text-text2">Project Members:</p>
            <div class="flex pt-2 pl-2 whitespace-pre flex-wrap">
              <div class="contents">
                <div v-for="(accepm, index1) in filterObject.projectAssignToUsers" :key="index1" class="flex items-center p1-2">
                  <div  class="row_user_svg  bg-primary  overflow-hidden rounded-full flex justify-center items-center border-2 border-primary h-10 w-10 mb-2">
                    <span class="text-white heading-4 font-bold">{{accepm.firstName | SubStringFilter(0, 2)}}</span>
                  </div>
                  <div class="pl-0.5 text-text2 heading-4">
                    {{accepm.firstName}} {{accepm.lastName}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center mt-5">
            <input type="checkbox" class="h-5 w-5 cursor-pointer" disabled v-model="filterObject.isActive">
            <p class=" text-text2 pl-2 heading-4">Project is Active</p>
          </div>
          <p class="pr-2 absolute top-3 right-1">
            <span class="px-1 pr-3 cursor-pointer" @click="addUserPopup()">
              <i class="fas fa-edit text-primary h-5 w-5" ></i>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="adduserDialog" class="popup_overlay">
      <div class="bg-white rounded-sm p-4 custom_dialog" style="width:550px">
        <addUser :userId="userDetailId"></addUser>
      </div>
    </div>
  </div>
</template>
<script>
import HRM from '@/View/CMS/api/HRM.js'
import addUser from '@/View/CMS/project/addProject.vue'
export default {
  components: {
    addUser,
  },
  data () {
    return {
      userDetailId: 0,
      adduserDialog: false,
      projectObj: [],
      filterObject: {
        projectDetailId: 0,
        projectName: '',
        notes: '',
        isActive: true,
        projectAssignToUsers: []
      }
    }
  },
  created() {
  },
  mounted () {
    document.title = 'Project OverView'
    this.getProjectDetail()
    this.$root.$on("closeDialog", (data) => {
      if (data === false) {
        this.adduserDialog = false
      }
      this.getProjectDetail()
    });
  },
  watch: {
  },
  methods: {
    addUserPopup () {
      this.userDetailId = this.projectObj.projectDetailId
      this.adduserDialog = true
    },
    getProjectDetail() {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
      HRM.getSpecProjectDetail(
        JSON.parse(this.$route.params.projectId),
        response => {
          console.log(response.Data)
          let temp = response.Data
          this.projectObj = response.Data
          this.filterObject.projectDetailId = temp.projectDetailId
          this.filterObject.projectName = temp.projectName
          this.filterObject.notes = temp.notes
          this.filterObject.isActive = temp.isActive
          let data = JSON.parse(temp.projectAssignToUsers)
          if (data.projectUsers !== null) {
          this.filterObject.projectAssignToUsers = data.projectUsers
          } else {
            this.filterObject.projectAssignToUsers = null
          }
          console.log('this.filterObject', this.filterObject)
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
        },
        error => {
          // console.log(error)
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
    this.$root.$off('closeDialog')
  }
}
</script>